// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")
//= require jquery-ui
//= require ./klaro/config.js
//= require ./klaro.js
import "bootstrap"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(function () {
  $(".alert-message").each(function () {
    if ($(this).text().trim().length) {
      $(this).parent().show()
      if ($(this).parent().hasClass("alert-success")) {
        $(this).parent().delay(2000).fadeOut(1000)
      }
      if ($(this).parent().hasClass("alert-warning")) {
        $(this).parent().delay(2000).fadeOut(1000)
      }
    }
  })
})
